import { RecordModel } from "pocketbase";
import { ArtifactMatcher } from "../domain/ArtifactMatcher";
import DeploymentTargetService from "./DeploymentTargetService";

const ArtifactMatcherService = {
    transformRecord: (record: RecordModel) => {
        let res: ArtifactMatcher = {
            id: record.id,
            created: new Date(record.created),
            updated: new Date(record.updated),
            name: record["name"],
            description: record["description"],
            deploymentTargetIDs: record["deployment_targets"],
            deploymentTargets: record.expand && record.expand["deployment_targets"]
                ? record.expand["deployment_targets"].map((r: RecordModel) => DeploymentTargetService.transformRecord(r))
                : [],
            namePattern: record["name_pattern"],
            projectID: record["project"],
            tagPattern: record["tag_pattern"],
        }
        return res;
    },
}

export default ArtifactMatcherService;
import { usePocket } from '../contexts/PocketContext';
import PocketBase from "pocketbase";
import { Box, Dialog, IconButton, ListItem, ListItemSecondaryAction, ListItemText, Tab, Tabs } from "@mui/material";
import Icon from '@mui/material/Icon';
import { Project } from "../domain/Project";
import { useTheme } from "@mui/material/styles"
import { ArtifactMatcher } from "../domain/ArtifactMatcher";
import { DeploymentTarget } from "../domain/DeploymentTarget";
import { Deployment } from "../domain/Deployment";
import React from "react";

interface DeploymentItemProps {
    project: Project;
    artifactMatcher: ArtifactMatcher;
    deployment: Deployment;
}

interface DeploymentDialogProps {
    deployment: Deployment;
    logs: {
        [key: string]: {
            name: string;
            lines: string[];
        }
    };
}
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

export default function DeploymentListItem({ project, artifactMatcher, deployment }: DeploymentItemProps) {
    const { pb }: { pb: PocketBase } = usePocket();
    const theme = useTheme();
    const [dialogInfo, setDialogInfo] = React.useState<DeploymentDialogProps | undefined>(undefined);
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return <ListItem
        onClick={() => {
        }} >
        <ListItemText primary={deployment.message}
            secondary={deployment.updated.toLocaleString() + " - " + deployment.model_json.image.fullName}>

        </ListItemText>

        <Dialog open={dialogInfo !== undefined} onClose={() => {
            setDialogInfo(undefined);
        }} PaperProps={{
            sx: {
                width: "50%",
                borderRadius: '0',
                backgroundColor: "#190782",
            }
        }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Deployment events" {...a11yProps(0)} />
                {dialogInfo?.logs ? Object.keys(dialogInfo.logs).map((key, i) => {
                    return <Tab key={i} label={key} {...a11yProps(1 + i)} />
                }) : undefined}
            </Tabs>
            <CustomTabPanel value={value} index={0}>
                {dialogInfo?.deployment && dialogInfo?.deployment.logs ? dialogInfo?.deployment.logs.map((log, i) => {
                    return <pre key={i}>{log.text}</pre>
                }) : undefined}
            </CustomTabPanel>
            {dialogInfo?.logs ? Object.keys(dialogInfo.logs).map((key, i) => {
                return <CustomTabPanel key={i} value={value} index={1 + i}>
                    {dialogInfo.logs[key].lines.map((line, o) => {
                        return <pre key={o}>{line}</pre>
                    })}
                </CustomTabPanel>
            }) : undefined}
        </Dialog>

        <ListItemSecondaryAction>
            <IconButton onClick={(ev) => {
                ev.stopPropagation();
                pb.send("/api/collections/deployments/records/" + deployment.id + "/logs", {

                })
                    .then((res) => {
                        console.log(res);
                        setDialogInfo({
                            deployment: deployment,
                            logs: res
                        });
                    });
            }}
                sx={{
                    "&:hover": {
                        backgroundColor: theme.palette.grey[100],
                        cursor: "pointer",
                        "& .logsIcon": {
                            color: "rgb(2 3 18)"
                        }
                    }
                }}>
                <Icon className="logsIcon">article</Icon>
            </IconButton>
        </ListItemSecondaryAction>

    </ListItem>
}
import { RecordModel } from "pocketbase";
import { DeploymentTargetType } from "../domain/DeploymentTargetType";

const DeploymentTargetTypeService = {
    transformRecord: (record: RecordModel) => {
        let res: DeploymentTargetType = {
            id: record.id,
            created: new Date(record.created),
            updated: new Date(record.updated),
            type: record["type"],
            typeConfig: record["type_config"],
            name: record["name"],
            projectID: record["project"],
        }
        return res;
    },
}

export default DeploymentTargetTypeService;
import { RecordModel } from "pocketbase";
import { DeploymentTarget } from "../domain/DeploymentTarget";
import DeploymentTargetTypeService from "./DeploymentTargetTypeService";

const DeploymentTargetService = {
    transformRecord: (record: RecordModel) => {
        let res: DeploymentTarget = {
            id: record.id,
            created: new Date(record.created),
            updated: new Date(record.updated),
            typeID: record["type"],
            type: record.expand && record.expand["type"] ? DeploymentTargetTypeService.transformRecord(record.expand["type"]) : undefined,
            deploymentTemplateID: record["deployment_template"],
            description: record["description"],
            projectID: record["project"],
            templateConfig: record["template_config"],
        }
        return res;
    },
}

export default DeploymentTargetService;
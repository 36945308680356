import * as React from 'react';
import Grid from '@mui/material/Grid';
import { usePocket } from '../contexts/PocketContext';
import PocketBase from "pocketbase";
import OrgService from '../services/OrgService';
import { Outlet, useParams } from 'react-router-dom';
import ProjectService from '../services/ProjectService';
import BaseColumn from '../components/BaseColumn';
import ProjectListItem from '../components/ProjectListItem';
import useSWR from 'swr';
import JSONFormDialog, { JSONFormDialogInfo } from '../components/JSONFormDialog';
import dataJSONSchema from '../schemas/project.json';
import DeploymentService from '../services/DeploymentService';
import NotificationService from '../services/NotificationService';
import DeploymentTargetService from '../services/DeploymentTargetService';

export default function Main() {
    const { pb }: { pb: PocketBase } = usePocket();
    const { orgid } = useParams();
    const [dialogInfo, setDialogInfo] = React.useState<JSONFormDialogInfo | undefined>(undefined);

    React.useEffect(() => {
        pb.collection("deployments").subscribe("*", async (data) => {
            switch (data.action) {
                case "create":
                    var deployment = DeploymentService.transformRecord(data.record);
                    var deploymentTarget = await pb.collection("deployment_targets").getOne(deployment.deployment_target)
                        .then((res) => {
                            return DeploymentTargetService.transformRecord(res);
                        })
                    var project = await pb.collection("projects").getOne(deploymentTarget.projectID)
                        .then((res) => {
                            return ProjectService.transformRecord(res);
                        })
                    NotificationService.notifySuccess("Deployment created for project " + project.name + " on target " + deploymentTarget.description);
                    break;
                case "update":
                    var deployment = DeploymentService.transformRecord(data.record);
                    var deploymentTarget = await pb.collection("deployment_targets").getOne(deployment.deployment_target)
                        .then((res) => {
                            return DeploymentTargetService.transformRecord(res);
                        })
                    var project = await pb.collection("projects").getOne(deploymentTarget.projectID)
                        .then((res) => {
                            return ProjectService.transformRecord(res);
                        })
                    NotificationService.notifySuccess("Deployment updated for project "
                        + project.name + " on target "
                        + deploymentTarget.description + ": " + deployment.status);
                    break;

                default:
                    break;
            }

        });
        return () => {
            pb.collection("deployments").unsubscribe("*");
        }
    }, [orgid]);

    const orgData = useSWR([
        "/orgs/" + orgid, orgid],
        async ([url, orgid]) => {
            if (!orgid) {
                return;
            }
            return await pb.collection("orgs").getOne(orgid)
                .then((res) => {
                    return OrgService.transformRecord(res);
                })
        }
    );

    const projectsData = useSWR([
        "/projects", orgid],
        async ([url, orgid]) => {
            if (!orgid) {
                return;
            }
            return await pb.collection("projects").getFullList({ 
                filter: "org = '" + orgid + "'",
                sort: "name"
             })
                .then((res) => {
                    return res.map((item) => {
                        return ProjectService.transformRecord(item);
                    })
                })
        }
    );

    return <Grid container spacing={3} sx={{ marginTop: "0px", height: "100%" }} alignItems="stretch">
        <Grid item xs={12} md={6} lg={3} sx={{ flexGrow: 1, height: "100%" }}>
            <BaseColumn title={'Projects'} onAdd={() => {
                console.log("Add project");
                setDialogInfo({
                    displayName: "Add Project",
                    dataJSONSchema: dataJSONSchema
                });
            }} isLoading={projectsData.isLoading || orgData.isLoading}>
                {projectsData && projectsData.data && orgData && orgData.data ? projectsData.data.map((k, i) => {
                    return <ProjectListItem key={i} project={k} orgName={orgData.data?.name as string}></ProjectListItem>
                }) : undefined}
            </BaseColumn>
        </Grid>
        <Grid item xs={12} md={6} lg={9} sx={{ flexGrow: 1, height: "100%" }}>
            <Outlet />
        </Grid>
    </Grid>;
}

import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import PocketBase from "pocketbase";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import PersonIcon from '@mui/icons-material/Person';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LogoutIcon from '@mui/icons-material/Logout';
import AppsIcon from '@mui/icons-material/Apps';
import { Box } from '@mui/material';
import useSWR from 'swr';
import { usePocket } from '../contexts/PocketContext';
import OrgService from '../services/OrgService';

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        backgroundColor: "#404154",
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        // '& .MuiMenuItem-root': {
        //     '& .MuiSvgIcon-root': {
        //         fontSize: 18,
        //         color: theme.palette.text.secondary,
        //         marginRight: theme.spacing(1.5),
        //     },
        //     '&:active': {
        //         backgroundColor: alpha(
        //             theme.palette.primary.main,
        //             theme.palette.action.selectedOpacity,
        //         ),
        //     },
        // },
    },
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    // backgroundColor: "#ffffff",
    // color: "#000",
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        })
    }),
}));

export default function Nav() {
    const nav = useNavigate();
    const { pb }: { pb: PocketBase } = usePocket();
    const { orgid } = useParams();

    const [accountMenuAnchorEl, setAccountMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const accountMenuOpen = Boolean(accountMenuAnchorEl);
    const handleAccountMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAccountMenuAnchorEl(event.currentTarget);
    };
    const handleAccountMenuClose = () => {
        setAccountMenuAnchorEl(null);
    };

    const orgData = useSWR([
        "/orgs/" + orgid, orgid],
        async ([url, orgid]) => {
            if (!orgid) {
                return;
            }
            return await pb.collection("orgs").getOne(orgid)
                .then((res) => {
                    return OrgService.transformRecord(res);
                });
        }
    );

    return <div>
        <AppBar position="absolute" style={{
            //backgroundColor: "#ffffff",
            borderBottom: '#ffffff45',
            borderStyle: 'none none solid',
            borderWidth: 'thin'
        }} elevation={0}>
            <Toolbar
                sx={{
                    pr: '24px', // keep right padding when drawer closed
                }}
            >
                <Link to={"/orgs"} style={{ color: "white" }}>
                    <IconButton>
                        <AppsIcon />
                    </IconButton>
                </Link>

                <Box sx={{ flexGrow: 1, marginLeft: "8px" }} component="div">
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                    >
                        simple-deploy.de
                    </Typography>
                    <Typography
                        variant="caption" display="block" gutterBottom>
                        Organization <b>{orgData && orgData.data ? orgData.data.name : ""}</b>
                    </Typography>
                </Box>
                {/* <IconButton color="inherit">
                    <Badge badgeContent={4} color="secondary">
                        <NotificationsIcon />
                    </Badge>
                </IconButton> */}
                <IconButton color="inherit" onClick={handleAccountMenuClick}>
                    <Badge color="secondary">
                        <PersonIcon />
                    </Badge>
                </IconButton>
            </Toolbar>
        </AppBar>

        <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={accountMenuAnchorEl}
            open={accountMenuOpen}
            onClose={handleAccountMenuClose}
        >
            <Divider sx={{ my: 0.5 }} />
            <MenuItem onClick={() => {
                nav("/sign-in");
            }} disableRipple>
                <LogoutIcon />
                Logout
            </MenuItem>
        </StyledMenu>
    </div >;
}
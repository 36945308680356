import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import SignIn from './pages/SignIn';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { PocketProvider } from './contexts/PocketContext';
import SignUp from './pages/SignUp';
import Orgs from './pages/Orgs';
import App from './App';
import OrgDetail from './pages/OrgDetail';
import ArtifactMatcherColumn from './pages/ArtifactMatcherColumn';
import DeploymentTargetColumn from './pages/DeploymentTargetColumn';
import DeploymentColumn from './pages/DeploymentColumn';
import Main from './pages/Main';
import Main_ProjectDetails from './pages/Main_ProjectDetails';
import Main_ArtifactMatchersTabs from './pages/Main_ArtifactMatchersTabs';
import Main_ArtifactMatcherTab_Events from './pages/Main_ArtifactMatcherTab_Events';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = createTheme({
  palette: {
    //primary: { main: "#3a34d2" }
    //primary: { main: "#ffffff" }
    // primary: { main: "#959ed7" },
    // secondary: { main: "#f50057" },
    // background: {
    //   default: "#1b043b",
    //   paper: "#1b043b"
    // },
    mode: 'dark',
    background: {
      default: "transparent",
      paper: "transparent"
    },
  }
  // palette: {
  //   mode: 'dark',
  // },
});
root.render(
  <React.StrictMode>
    <PocketProvider>
      <BrowserRouter>
        <SnackbarProvider />
        <ThemeProvider theme={theme}>
          <Routes>
            <Route index element={<Navigate to={'/sign-in'} />}  />
            <Route path="/sign-in" element={<SignIn />} />
            <Route element={<App />}>

              <Route path="/orgs" element={<Orgs />} />

              <Route path="/orgs/:orgid"
                element={<Main />} >

                <Route path="/orgs/:orgid/projects/:projid"
                  element={<Main_ProjectDetails />} />

                <Route path="/orgs/:orgid/projects/:projid/artifactMatchers/:artmatchid/deploymentTargets/:deploytargetid"
                  element={<Main_ProjectDetails />}>

                </Route>
              </Route>
            </Route>
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </PocketProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

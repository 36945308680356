import { ListItem, ListItemText } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { Org } from "../domain/Org";



interface OrgListItemProps {
    org: Org;
}

export default function OrgListItem({ org }: OrgListItemProps) {

    const nav = useNavigate();

    return <ListItem
        onClick={() => {
            if (org.id) {
                nav("/orgs/" + org.id);
            }
        }} >
        <ListItemText
            primary={org.name}
            secondary={'Organization TODO stats,plan'}
        />
    </ListItem>
}
import * as React from 'react';
import Grid from '@mui/material/Grid';
import { usePocket } from '../contexts/PocketContext';
import PocketBase from "pocketbase";
import OrgService from '../services/OrgService';
import { Outlet, useParams } from 'react-router-dom';
import ProjectService from '../services/ProjectService';
import BaseColumn from '../components/BaseColumn';
import ProjectListItem from '../components/ProjectListItem';
import useSWR from 'swr';
import JSONFormDialog, { JSONFormDialogInfo } from '../components/JSONFormDialog';
import dataJSONSchema from '../schemas/project.json';
import DeploymentService from '../services/DeploymentService';
import NotificationService from '../services/NotificationService';
import DeploymentTargetService from '../services/DeploymentTargetService';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CircularProgress, Container, Icon, IconButton, Tab, Tabs, Typography, useTheme } from '@mui/material';
import RegistryTokenService from '../services/RegistryTokenService';
import Main_ArtifactMatchersTabs from './Main_ArtifactMatchersTabs';

export default function Main_ProjectDetails() {
    const { pb }: { pb: PocketBase } = usePocket();
    const theme = useTheme();
    const { orgid, projid } = useParams();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const orgData = useSWR([
        "/orgs/" + orgid, orgid],
        async ([url, orgid]) => {
            if (!orgid) {
                return;
            }
            return await pb.collection("orgs").getOne(orgid)
                .then((res) => {
                    return OrgService.transformRecord(res);
                })
        }
    );

    const projectData = useSWR([
        "/projects/" + projid, projid],
        async ([url, projid]) => {
            if (!projid) {
                return;
            }
            return await pb.collection("projects").getOne(projid)
                .then((res) => {
                    return ProjectService.transformRecord(res);
                });
        }
    );

    const registryTokenData = useSWR([
        "/projects/" + projid + "/registry_token", projid],
        async ([url, projid]) => {
            if (!projid) {
                return;
            }
            return await pb.collection("registry_tokens").getFullList({ filter: "project = '" + projid + "'" })
                .then((res) => {
                    if (res.length === 0) {
                        return Promise.reject("No registry token found for project");
                    }
                    const token = RegistryTokenService.transformRecord(res[0]);
                    return token;
                });
        }
    );

    return <Card style={{
        height: "100%",
        borderRadius: "0",
        border: '#ffffff45',
        borderStyle: 'solid',
        borderWidth: 'thin',
        display: "flex",
        flexDirection: "column",
    }}
        elevation={0}
        sx={{
            minWidth: 320,
            '& .MuiListItem-root': {
                cursor: "pointer",
                "&:hover": {
                    backgroundColor: "#163451ad"
                },
                "&.selected": {
                    backgroundColor: "#163451ad"
                }
            },
        }}>
        <div style={{
            width: "100%",
            display: "flex",
            flexDirection: "row"
        }}>
            <Typography variant="h6" gutterBottom>
                {projectData.data?.name}
            </Typography>
            <span style={{
                flexGrow: 1
            }}></span>
            <IconButton onClick={(ev) => {
                ev.stopPropagation();
            }}
                sx={{
                    "&:hover": {
                        backgroundColor: theme.palette.grey[100],
                        cursor: "pointer",
                        "& .logsIcon": {
                            color: "rgb(2 3 18)"
                        }
                    }
                }}>
                <Icon className="logsIcon">settings</Icon>
            </IconButton>
        </div>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                How to connect?
            </AccordionSummary>
            <AccordionDetails>
                {orgData.isLoading || projectData.isLoading || registryTokenData.isLoading ? <CircularProgress /> : undefined}
                {orgData.data && projectData.data && registryTokenData.data ? <React.Fragment>
                    <Typography>Configure your CI / Build automation to publish your images to {projectData.data.typeConfig.url}</Typography>
                    <Typography>Images must be labeled with {`LABEL de.simple-deploy.app='{"secrets":{"APCA_API_KEY_ID":{"type":"ENV"},"APCA_API_SECRET_KEY":{"type":"ENV"}}}'`}</Typography>
                    {projectData.data.name === 'default' ? <Typography>
                        Images must be pushed using the following naming {`${orgData.data.name}/{your-image-name}:{latest | semver-tag}`}
                    </Typography> : undefined}
                    {projectData.data.name !== 'default' ? <Typography>
                        Images must be pushed using the following naming {`${projectData.data.typeConfig.url}/${orgData.data.name}/${projectData.data.name}/{your-image-name}:{latest | semver-tag}`}
                    </Typography> : undefined}
                    <Typography>Username for pushing {`${projectData.data.name}`}</Typography>
                    <Typography>Password for pushing {`${registryTokenData.data.token}`}</Typography>
                    <Typography>See here for GitHub examples</Typography>
                    <Typography>See here for GitLab examples</Typography>
                </React.Fragment> : undefined}
            </AccordionDetails>
        </Accordion>
        <div style={{
            flexGrow: 1,
            overflow: 'auto'
        }}>
            <Main_ArtifactMatchersTabs />
        </div>
    </Card>;
}

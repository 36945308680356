import { RecordModel } from "pocketbase";
import { Org } from "../domain/Org";

const OrgService = {
    transformRecord: (record: RecordModel) => {
        let res : Org = {
            id: record.id,
            created: new Date(record.created),
            updated: new Date(record.updated),
            name: record["name"],
            members: record["members"],
        }
        return res;
    },
}

export default OrgService;
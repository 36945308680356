import * as React from 'react';
import Grid from '@mui/material/Grid';
import { usePocket } from '../contexts/PocketContext';
import PocketBase from "pocketbase";
import OrgService from '../services/OrgService';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import ProjectService from '../services/ProjectService';
import BaseColumn from '../components/BaseColumn';
import ProjectListItem from '../components/ProjectListItem';
import useSWR from 'swr';
import JSONFormDialog, { JSONFormDialogInfo } from '../components/JSONFormDialog';
import dataJSONSchema from '../schemas/project.json';
import DeploymentService from '../services/DeploymentService';
import NotificationService from '../services/NotificationService';
import DeploymentTargetService from '../services/DeploymentTargetService';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CircularProgress, Container, Dialog, DialogContent, DialogTitle, Icon, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, ListSubheader, Tab, Tabs, Typography, useTheme } from '@mui/material';
import RegistryTokenService from '../services/RegistryTokenService';
import { Deployment } from '../domain/Deployment';
import LogDialog, { LogDialogInfo } from '../components/LogDialog';
import Main_ArtifactMatcherTab_Events from './Main_ArtifactMatcherTab_Events';

interface TabPanelProps {
    children?: React.ReactNode;
    id: string;
    selected: string;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, selected, id, ...other } = props;

    return (
        <div
            style={{ width: '100%', height: '100%' }}
            role="tabpanel"
            hidden={selected !== id}
            id={`simple-tabpanel-${id}`}
            aria-labelledby={`simple-tab-${id}`}
            {...other}
        >
            {selected === id && <Box sx={{ p: 3, width: '100%', height: '100%', display: 'flex', flexDirection: 'row' }}>{children}</Box>}
        </div>
    );
}

export default function Main_ArtifactMatcherTab({ myID }: { myID: string }) {
    const { pb }: { pb: PocketBase } = usePocket();
    const { orgid, projid, artmatchid, deploytargetid } = useParams();
    const [logDialogInfo, setLogDialogInfo] = React.useState<LogDialogInfo | undefined>(undefined);
    const nav = useNavigate();
    const loc = useLocation();
    const theme = useTheme();

    const orgData = useSWR([
        "/orgs/" + orgid, orgid],
        async ([url, orgid]) => {
            if (!orgid) {
                return;
            }
            return await pb.collection("orgs").getOne(orgid)
                .then((res) => {
                    return OrgService.transformRecord(res);
                })
        }
    );

    const projectData = useSWR([
        "/projects/" + projid, projid],
        async ([url, projid]) => {
            if (!projid) {
                return;
            }
            return await pb.collection("projects").getOne(projid)
                .then((res) => {
                    return ProjectService.transformRecord(res);
                });
        }
    );

    const imagesData = useSWR([
        "/artifact_matchers/" + artmatchid + "/images", artmatchid],
        async ([url, artmatchid]) => {
            if (!artmatchid) {
                return;
            }
            return await pb.send("/api/collections/images/records", {
                query: {
                    "artifactmatcherid": artmatchid
                }
            })
                .then((res) => {
                    var imageFullNames = Object.keys(res);
                    if (imageFullNames.length === 0) {
                        return imageFullNames;
                    }

                    return imageFullNames.sort();
                });
        }
    );

    // contains the latest deployment status by full image name {registry}/{org}/{project}...:{tag}
    const deploymentStatus = useSWR(imagesData && imagesData.data ? [
        "/deployment_targets/" + deploytargetid + "/status", deploytargetid] : null,
        async ([url, deploytargetid]) => {
            if (!deploytargetid) {
                return;
            }
            if (!imagesData.data) {
                return;
            }
            return await pb.send("/api/collections/deployment_targets/records/" + deploytargetid + "/status", {
                query: {
                    "images": imagesData.data?.join(",")
                }
            })
            .then((res) => {
                Object.keys(res).forEach((key) => {
                    res[key].model_json = DeploymentService.getDeployModel(res[key]);
                });
                return res;
            })
                .catch((err) => {
                    if (err.status === 404) {
                        return undefined;
                    }
                });
        }
    );

    if (!artmatchid) {
        return <React.Fragment />;
    }

    const getStatusColor = (image: string, latestDeployment: any) => {
        var color = 'grey'; // unknown

        if (!latestDeployment.data) {
            return color;
        }

        if (!latestDeployment.data[image]) {
            return color;
        }

        if (!latestDeployment.data[image].runtime_status) {
            return color;
        }

        if (!latestDeployment.data[image].runtime_status.images) {
            return color;
        }

        if (!latestDeployment.data[image].runtime_status.images[image]) {
            return color;
        }

        var status = latestDeployment.data[image].runtime_status.images[image].status;
        switch (status) {
            case 'RUNNING':
                color = 'green';
                break;
            case 'FAILED':
                color = 'red';
                break;
            case 'PENDING':
                color = 'yellow';
                break;
            default:
                color = 'grey';
                break;
        }

        return color;
    }

    const getStatusReason = (image: string, latestDeployment: any) => {
        var reason = '';

        if (!latestDeployment.data) {
            return reason;
        }

        if (!latestDeployment.data[image]) {
            return reason;
        }

        if (!latestDeployment.data[image].runtime_status) {
            return reason;
        }

        if (!latestDeployment.data[image].runtime_status.images) {
            return reason;
        }

        if (!latestDeployment.data[image].runtime_status.images[image]) {
            return reason;
        }

        return latestDeployment.data[image].runtime_status.images[image].reason;
    }

    return <CustomTabPanel selected={artmatchid} id={myID}>

        <LogDialog info={logDialogInfo} onClose={() => {
            setLogDialogInfo(undefined);
        }} />

        <Card sx={{ height: '100%', flexGrow: 0.5 }} style={{
            borderRadius: "0",
            border: '#ffffff45',
            borderStyle: 'solid',
            borderWidth: 'thin'
        }} elevation={0}>
            <List sx={{
                minWidth: 320,
            }}>
                <ListSubheader>{'Images'}</ListSubheader>
                {imagesData.isLoading && <CircularProgress />}
                {imagesData.data && imagesData.data.length > 0 ? imagesData.data.map((image) => {
                    return <ListItem key={image}>

                        <ListItemAvatar>
                            {deploymentStatus.isLoading && <CircularProgress />}
                            {deploymentStatus.data ? <Icon className="statusIcon" style={{
                                color: getStatusColor(image, deploymentStatus)
                            }}>circle</Icon> : undefined}
                        </ListItemAvatar>

                        <ListItemText primary={image}
                            secondary={deploymentStatus.data ? getStatusReason(image, deploymentStatus) : ''}>
                        </ListItemText>


                        <ListItemSecondaryAction>
                            {deploymentStatus.data
                                && deploymentStatus.data[image]
                                && deploymentStatus.data[image].model_json
                                && deploymentStatus.data[image].model_json.urls
                                && deploymentStatus.data[image].model_json.urls.length > 0 ? <IconButton onClick={(ev) => {
                                    ev.stopPropagation();
                                    if (!artmatchid) {
                                        return;
                                    }
                                    var url = deploymentStatus.data[image].model_json.urls[0];
                                    var tab = window.open(url, '_blank');
                                    tab?.focus();
                                }}
                                    sx={{
                                        "&:hover": {
                                            backgroundColor: theme.palette.grey[100],
                                            cursor: "pointer",
                                            "& .openIcon": {
                                                color: "rgb(2 3 18)"
                                            }
                                        }
                                    }}>
                                <Icon className="openIcon">open_in_new</Icon>
                            </IconButton> : undefined}
                            <IconButton onClick={(ev) => {
                                ev.stopPropagation();
                                if (!artmatchid) {
                                    return;
                                }
                                pb.send(`/api/collections/artifact_matchers/records/${artmatchid}/deploy`, {
                                    method: "POST",
                                    query: {
                                        "image": image
                                    }
                                })
                                    .then((res) => {
                                        console.log(res);
                                    });
                            }}
                                sx={{
                                    "&:hover": {
                                        backgroundColor: theme.palette.grey[100],
                                        cursor: "pointer",
                                        "& .deployIcon": {
                                            color: "rgb(2 3 18)"
                                        }
                                    }
                                }}>
                                <Icon className="deployIcon">refresh</Icon>
                            </IconButton>
                            <IconButton onClick={(ev) => {
                                ev.stopPropagation();
                                if (!deploymentStatus.data) {
                                    return;
                                }
                                var deployment = deploymentStatus.data[image];
                                if (!deployment) {
                                    return;
                                }
                                pb.send("/api/collections/deployments/records/" + deployment.id + "/logs", {

                                })
                                    .then((res) => {
                                        console.log(res);
                                        setLogDialogInfo({
                                            deployment: deployment,
                                            logs: res
                                        });
                                    });
                            }}
                                sx={{
                                    "&:hover": {
                                        backgroundColor: theme.palette.grey[100],
                                        cursor: "pointer",
                                        "& .logIcon": {
                                            color: "rgb(2 3 18)"
                                        }
                                    }
                                }}>
                                <Icon className="logIcon">article</Icon>
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                }) : undefined}
            </List>
        </Card>
        <Card sx={{ height: '100%', flexGrow: 0.5 }} style={{
            maxHeight: "100%",
            borderRadius: "0",
            border: '#ffffff45',
            borderStyle: 'solid',
            borderWidth: 'thin'
        }} elevation={0}>
            <Main_ArtifactMatcherTab_Events />
        </Card>
        {/* <Grid container spacing={2} sx={{ height: '100%', width: '100%' }} >
            <Grid item xs={6} m={6} lg={6}>
            </Grid>
            <Grid item xs={6} m={6} lg={6}>
                <List>
                    <ListSubheader>{'Events'}</ListSubheader>
                </List>
            </Grid>
        </Grid> */}
    </CustomTabPanel>;
}

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

import { useLocation } from 'react-router-dom';
import { RequireAuth } from './components/RequireAuth';
import Nav from './components/Nav';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="/">
        simple-deploy.de
      </Link>{' '}
      {"2023 - " + new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


function DashboardContent() {
  const location = useLocation();
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {location.pathname !== "/orgs" && <Nav />}
      <Box
        component="main"
        sx={{
          // backgroundColor: (theme) =>
          //   theme.palette.mode === 'light'
          //     ? "#fff"
          //     : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {location.pathname !== "/orgs" && <Toolbar />}
        <Container maxWidth={false} sx={{ flex: 1, overflow: "auto" }}>
          <RequireAuth />
        </Container>
        <Copyright sx={{ pt: 4 }} />
      </Box>
    </Box>
  );
}

export default function App() {

  // Initialize stores ...

  return <DashboardContent />;
}
// This is a custom json forms renderer that renders a text input field together with detailed description of the field.
import {
    ControlProps,
    RankedTester,
    rankWith,
    and,
    uiTypeIs
} from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Grid, Typography } from '@mui/material';



export const fieldDescriptionControl = (props: ControlProps) => {
    const p = props as any;

    const getTextWithLinks = (t: string | undefined) => {
        if (t === undefined) {
            return <Typography variant="body2" color="text.secondary" sx={{ whiteSpace: "pre-wrap", marginTop: "8px" }}></Typography>
        }
        const arr = t.split(' ');
        return <Typography variant="body2" color="text.secondary" sx={{ whiteSpace: "pre-wrap", marginTop: "8px" }}>
            {arr.map((s, i) => {
                if (s.startsWith("http://") || s.startsWith("https://")) {
                    return <a key={i} href={s} target="_blank" rel="noreferrer">{s + " "}</a>
                }
                return s + " ";
            })}
        </Typography>;
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                {getTextWithLinks(p.uischema.description)}
            </Grid>
        </Grid>
    );
};


export const fieldDescriptionControlTester: RankedTester = rankWith(
    3,
    and(
        uiTypeIs("description")
    ),
);
export default withJsonFormsControlProps(fieldDescriptionControl);
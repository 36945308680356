import { usePocket } from '../contexts/PocketContext';
import PocketBase from "pocketbase";
import { Card, CardContent, CardHeader, Dialog, IconButton, ListItem, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { useNavigate, useParams } from 'react-router-dom';
import Icon from '@mui/material/Icon';
import { Project } from "../domain/Project";
import { useTheme } from "@mui/material/styles"
import React from "react";
import RegistryTokenService from '../services/RegistryTokenService';

interface ProjectListItemProps {
    orgName: string;
    project: Project;
}

export default function ProjectListItem({ orgName, project }: ProjectListItemProps) {
    const { pb }: { pb: PocketBase } = usePocket();
    const { projid } = useParams();
    const [dialogInfo, setDialogInfo] = React.useState<any>(undefined);

    const theme = useTheme();
    const nav = useNavigate();

    return <ListItem
        className={projid === project.id ? "selected" : ""}
        onClick={() => {
            if (project.id) {
                nav("/orgs/" + project.orgID + "/projects/" + project.id + "#artifacts");
            }
        }} >
        <ListItemText primary={project.name}
            primaryTypographyProps={{
                paddingRight: 8
            }}
            secondaryTypographyProps={{
                paddingRight: 8
            }}
            secondary={project.type + " - " + project.typeConfig.url}>

        </ListItemText>

        <Dialog open={dialogInfo !== undefined} onClose={() => {
            setDialogInfo(undefined);
        }} PaperProps={{
            sx: {
                width: "50%",
                borderRadius: '0',
                backgroundColor: "#190782",
            }
        }}>
            <Card>
                <CardHeader title={'How to connect'}></CardHeader>
                <CardContent>
                    <Typography>Configure your CI / Build automation to publish your images to {project.typeConfig.url}</Typography>
                    <Typography>Images must be labeled with {`LABEL de.simple-deploy.app='{"secrets":{"APCA_API_KEY_ID":{"type":"ENV"},"APCA_API_SECRET_KEY":{"type":"ENV"}}}'`}</Typography>
                    <Typography>Images must be pushed using the following naming {`${project.typeConfig.url}/${orgName}/${project.name}/{your-image-name}:{latest | semver-tag}`}</Typography>
                    <Typography>Username for pushing {`${project.name}`}</Typography>
                    {dialogInfo ? <Typography>Password for pushing {`${dialogInfo.token}`}</Typography> : undefined}
                    <Typography>See here for GitHub examples</Typography>
                    <Typography>See here for GitLab examples</Typography>
                </CardContent>
            </Card>
        </Dialog>

        {/* <ListItemSecondaryAction>
            <IconButton onClick={(ev) => {
                ev.stopPropagation();
                pb.collection("registry_tokens").getFullList({ filter: "project = '" + project.id + "'" })
                    .then((res) => {
                        if (res.length === 0) {
                            return Promise.reject("No registry token found for project");
                        }
                        const token = RegistryTokenService.transformRecord(res[0]);

                        setDialogInfo(token);
                        return token;
                    });
            }}
                sx={{
                    "&:hover": {
                        backgroundColor: theme.palette.grey[100],
                        cursor: "pointer",
                        "& .keyIcon": {
                            color: "rgb(2 3 18)"
                        }
                    }
                }}>
                <Icon className="keyIcon">key</Icon>
            </IconButton>
        </ListItemSecondaryAction> */}
    </ListItem>
}
import {
    createContext,
    useContext,
    useCallback,
    useState,
    useEffect,
    useMemo,
} from "react";
import PocketBase from "pocketbase";
import { useInterval } from "usehooks-ts";
import { jwtDecode } from "jwt-decode";
import ms from "ms";

const BASE_URL = process.env.NODE_ENV === "development" ? "http://127.0.0.1:8090" : undefined;
const fiveMinutesInMs = ms("5 minutes");
const twoMinutesInMs = ms("2 minutes");

const PocketContext = createContext({});

export const PocketProvider = ({ children }: { children: any }) => {
    const pb = useMemo(() => new PocketBase(BASE_URL), []);
    pb.autoCancellation(false);

    const [token, setToken] = useState(pb.authStore.token);
    const [user, setUser] = useState(pb.authStore.model);

    useEffect(() => {
        return pb.authStore.onChange((token, model) => {
            setToken(token);
            setUser(model);
        });
    }, [pb]);

    const register = useCallback(async (email: string, password: string) => {
        return await pb
            .collection("users")
            .create({ email, password, passwordConfirm: password });
    }, [pb]);

    const login = useCallback(async (email: string, password: string) => {
        return await pb.collection("users").authWithPassword(email, password);
    }, [pb]);

    const loginWithOauth2 = useCallback(async (provider: string) => {
        return await pb.collection('users').authWithOAuth2({ provider: provider });
    }, [pb]);

    const logout = useCallback(() => {
        pb.authStore.clear();
    }, [pb.authStore]);

    const refreshSession = useCallback(async () => {
        if (!pb.authStore.isValid) return;
        const decoded = jwtDecode(token);
        const tokenExpiration = decoded.exp;
        if (!tokenExpiration) return;
        const expirationWithBuffer = (tokenExpiration + fiveMinutesInMs) / 1000;
        if (tokenExpiration < expirationWithBuffer) {
            await pb.collection("users").authRefresh();
        }
    }, [token, pb]);

    useInterval(refreshSession, token ? twoMinutesInMs : null);

    return (
        <PocketContext.Provider
            value={{ register, login, loginWithOauth2, logout, user, token, pb }}
        >
            {children}
        </PocketContext.Provider>
    );
};

export const usePocket = () => useContext(PocketContext) as any;
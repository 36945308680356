import * as React from 'react';
import Grid from '@mui/material/Grid';
import { usePocket } from '../contexts/PocketContext';
import PocketBase from "pocketbase";
import OrgService from '../services/OrgService';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import ProjectService from '../services/ProjectService';
import BaseColumn from '../components/BaseColumn';
import ProjectListItem from '../components/ProjectListItem';
import useSWR from 'swr';
import JSONFormDialog, { JSONFormDialogInfo } from '../components/JSONFormDialog';
import dataJSONSchema from '../schemas/project.json';
import DeploymentService from '../services/DeploymentService';
import NotificationService from '../services/NotificationService';
import DeploymentTargetService from '../services/DeploymentTargetService';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CircularProgress, Container, Icon, IconButton, List, ListSubheader, Tab, Tabs, Typography, useTheme } from '@mui/material';
import RegistryTokenService from '../services/RegistryTokenService';
import ArtifactMatcherService from '../services/ArtifactMatcherService';
import DeploymentListItem from '../components/DeploymentListItem';
import { Project } from '../domain/Project';
import { ArtifactMatcher } from '../domain/ArtifactMatcher';
import { DeploymentTarget } from '../domain/DeploymentTarget';

export default function Main_ArtifactMatcherTab_Events() {
    const { pb }: { pb: PocketBase } = usePocket();
    const { orgid, projid, artmatchid, deploytargetid, imagepath } = useParams();
    const nav = useNavigate();

    const orgData = useSWR([
        "/orgs/" + orgid, orgid],
        async ([url, orgid]) => {
            if (!orgid) {
                return;
            }
            return await pb.collection("orgs").getOne(orgid)
                .then((res) => {
                    return OrgService.transformRecord(res);
                })
        }
    );

    const projectData = useSWR([
        "/projects/" + projid, projid],
        async ([url, projid]) => {
            if (!projid) {
                return;
            }
            return await pb.collection("projects").getOne(projid)
                .then((res) => {
                    return ProjectService.transformRecord(res);
                });
        }
    );

    const artifactMatcherData = useSWR([
        "/artifact_matchers/" + artmatchid, artmatchid],
        async ([url, artmatchid]) => {
            if (!artmatchid) {
                return;
            }
            return await pb.collection("artifact_matchers").getOne(artmatchid)
                .then((res) => {
                    return ArtifactMatcherService.transformRecord(res);
                });
        }
    );

    const deploymentsData = useSWR([
        "/deployments", deploytargetid],
        async ([url, deploytargetid]) => {
            if (!deploytargetid) {
                return;
            }
            return await pb.collection("deployments").getList(undefined, 10, {
                filter: "deployment_target = '" + deploytargetid + "'",
                sort: "-updated"
            })
                .then((res) => {
                    return res.items.map((item) => {
                        return DeploymentService.transformRecord(item);
                    });
                });
        }
    );

    if (!artmatchid) {
        return <React.Fragment />;
    }


    return <List sx={{ height: '100%', maxHeight: '100%', overflow: 'auto' }}>
        <ListSubheader>{'Events'}</ListSubheader>
        {deploymentsData.isLoading && <CircularProgress />}
        {projectData.data && artifactMatcherData.data && deploymentsData.data ? deploymentsData.data.map((item) => {
            return <DeploymentListItem key={item.id}
                project={projectData.data as Project}
                artifactMatcher={artifactMatcherData.data as ArtifactMatcher}
                deployment={item} />;
        }) : undefined}
    </List>;
}

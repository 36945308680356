import { RecordModel } from "pocketbase";
import { Project } from "../domain/Project";

const ProjectService = {
    transformRecord: (record: RecordModel) => {
        let res : Project = {
            id: record.id,
            created: new Date(record.created),
            updated: new Date(record.updated),
            name: record["name"],
            orgID: record["org"],
            secret: record["secret"],
            type: record["type"],
            typeConfig: record["type_config"],
        }
        return res;
    },
}

export default ProjectService;
import { RecordModel } from "pocketbase";
import { RegistryToken } from "../domain/RegistryToken";

const RegistryTokenService = {
    transformRecord: (record: RecordModel) => {
        let res : RegistryToken = {
            id: record.id,
            created: new Date(record.created),
            updated: new Date(record.updated),
            projectID: record["project"],
            token: record["token"],
        }
        return res;
    },
}

export default RegistryTokenService;
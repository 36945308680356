import * as React from 'react';
import Grid from '@mui/material/Grid';
import { usePocket } from '../contexts/PocketContext';
import PocketBase from "pocketbase";
import OrgService from '../services/OrgService';
import { Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import ProjectService from '../services/ProjectService';
import BaseColumn from '../components/BaseColumn';
import ProjectListItem from '../components/ProjectListItem';
import useSWR from 'swr';
import JSONFormDialog, { JSONFormDialogInfo } from '../components/JSONFormDialog';
import dataJSONSchema from '../schemas/project.json';
import DeploymentService from '../services/DeploymentService';
import NotificationService from '../services/NotificationService';
import DeploymentTargetService from '../services/DeploymentTargetService';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CircularProgress, Container, Icon, IconButton, Tab, Tabs, Typography, useTheme } from '@mui/material';
import RegistryTokenService from '../services/RegistryTokenService';
import ArtifactMatcherService from '../services/ArtifactMatcherService';
import Main_ArtifactMatcherTab from './Main_ArtifactMatcherTab';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(id: string) {
    return {
        id: `simple-tab-${id}`,
        'aria-controls': `simple-tabpanel-${id}`,
    };
}

export default function Main_ArtifactMatchersTabs() {
    const { pb }: { pb: PocketBase } = usePocket();
    const theme = useTheme();
    const { orgid, projid, artmatchid } = useParams();
    const nav = useNavigate();

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        nav(`/orgs/${orgid}/projects/${projid}/artifactMatchers/${newValue}`);
    };

    const orgData = useSWR([
        "/orgs/" + orgid, orgid],
        async ([url, orgid]) => {
            if (!orgid) {
                return;
            }
            return await pb.collection("orgs").getOne(orgid)
                .then((res) => {
                    return OrgService.transformRecord(res);
                })
        }
    );

    const projectData = useSWR([
        "/projects/" + projid, projid],
        async ([url, projid]) => {
            if (!projid) {
                return;
            }
            return await pb.collection("projects").getOne(projid)
                .then((res) => {
                    return ProjectService.transformRecord(res);
                });
        }
    );

    const artifactMatchersData = useSWR([
        "/artifact_matchers", projid],
        async ([url, projid]) => {
            if (!projid) {
                return;
            }
            return await pb.collection("artifact_matchers").getFullList({
                filter: "project = '" + projid + "'",
                sort: "name",
                expand: "deployment_targets,deployment_targets.type"
            })
                .then((res) => {
                    return res.map((item) => {
                        return ArtifactMatcherService.transformRecord(item);
                    });
                });
        }
    );

    if (!artmatchid
        && artifactMatchersData.data
        && artifactMatchersData.data.length > 0
        && artifactMatchersData.data[0].deploymentTargets
        && artifactMatchersData.data[0].deploymentTargets.length > 0
        && projectData.data
    ) {
        return <Navigate to={`/orgs/${projectData.data.orgID}/projects/${projectData.data.id}/artifactMatchers/${artifactMatchersData.data[0].id}/deploymentTargets/${artifactMatchersData.data[0].deploymentTargets[0].id}`}></Navigate>
    }

    return <Card style={{
        borderRadius: "0",
        border: '#ffffff45',
        borderStyle: 'solid',
        borderWidth: 'thin',
        height: "100%",
        width: "100%",
        display: 'flex', 
        flexDirection: 'column'
    }}
        elevation={0}>
        {artifactMatchersData.isLoading ? <CircularProgress /> : undefined}
        {artifactMatchersData.data && artifactMatchersData.data.length > 0 ? <React.Fragment>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={artmatchid} onChange={(event: React.SyntheticEvent, newValue: string) => {
                    if (!artifactMatchersData.data) {
                        return;
                    }
                    var artifactMatcher = artifactMatchersData.data.find((item) => item.id === newValue);
                    if (!artifactMatcher) {
                        return;
                    }
                    if (!artifactMatcher.deploymentTargets) {
                        return;                        
                    }
                    if (artifactMatcher.deploymentTargets.length === 0) {
                        return;
                    }
                    nav(`/orgs/${orgid}/projects/${projid}/artifactMatchers/${newValue}/deploymentTargets/${artifactMatcher.deploymentTargets[0].id}`);
                }} aria-label="basic tabs example">
                    {artifactMatchersData.data.map((item) => {
                        return <Tab key={item.id as string} label={item.name} {...a11yProps(item.id as string)} value={item.id as string}/>
                    })}
                </Tabs>
            </Box>
            <Box sx={{ flexGrow: 1, overflow: "auto" }}>
                <Main_ArtifactMatcherTab key={artmatchid} myID={artmatchid as string}></Main_ArtifactMatcherTab>
            </Box>
        </React.Fragment> : undefined}
    </Card>;
}

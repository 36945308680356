import { Button, Card, CircularProgress, List, ListItem, ListSubheader, Typography } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion"

const pageVariants = {
    initial: {
        opacity: 0
    },
    in: {
        opacity: 1
    },
    out: {
        opacity: 0
    }
};

const pageTransition = {
    type: 'tween',
    ease: 'linear',
    duration: 0.5
};

export default function BaseColumn({ children, onClick, title, onAdd, isLoading }: any) {
    
    return <AnimatePresence>
        <motion.div initial="initial"
            style={{
                height: "100%",
            }}
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}>
            <Card style={{
                height: "100%",
                borderRadius: "0",
                border: '#ffffff45',
                borderStyle: 'solid',
                borderWidth: 'thin'
            }} elevation={0}
                onClick={() => {
                    if (onClick) {
                        onClick();
                    }
                }}
                sx={{
                    minWidth: 320,
                    '& .MuiListItem-root': {
                        cursor: "pointer",
                        "&:hover": {
                            backgroundColor: "#163451ad"
                        },
                        "&.selected": {
                            backgroundColor: "#163451ad"
                        }
                    },
                }}>
                <div style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start"
                }}>
                    <List subheader={
                        <ListSubheader component="div" sx={{ display: 'flex' }}>
                            {title}
                            <span style={{
                                flexGrow: 1
                            }}></span>
                            {onAdd ? <Button onClick={(ev) => {
                                ev.stopPropagation();
                                onAdd();
                            }}>+ Add</Button> : undefined}
                        </ListSubheader>
                    } sx={
                        {
                            height: "100%",
                            flexGrow: 1,
                            overflowY: "auto"
                        }
                    }>
                        {children}
                        {children && children.length === 0 && onAdd ? <ListItem>
                            <Typography variant="body2" color="text.secondary" sx={{ whiteSpace: "pre-wrap" }}>
                                Nothing to show here. Add something!
                            </Typography>
                        </ListItem> : undefined}
                        {children && children.length === 0 && !onAdd ? <ListItem>
                            <Typography variant="body2" color="text.secondary" sx={{ whiteSpace: "pre-wrap" }}>
                                Nothing to show here.
                            </Typography>
                        </ListItem> : undefined}

                        {isLoading ? <ListItem sx={{
                            justifyContent: "center",
                        }}>
                            <CircularProgress />
                        </ListItem> : undefined}
                    </List>
                </div>
            </Card>
        </motion.div>
    </AnimatePresence>
}
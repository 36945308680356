import * as React from 'react';
import { usePocket } from '../contexts/PocketContext';
import PocketBase, { AuthModel } from "pocketbase";
import OrgService from '../services/OrgService';
import { Button, CircularProgress, Container, List, ListItem, Typography } from '@mui/material';
import OrgListItem from '../components/OrgListItem';
import JSONFormDialog, { JSONFormDialogInfo } from '../components/JSONFormDialog';
import dataJSONSchema from '../schemas/org.json';
import useSWR from 'swr';

export default function Orgs() {
    const { pb, user }: { pb: PocketBase, user: AuthModel } = usePocket();
    const [dialogInfo, setDialogInfo] = React.useState<JSONFormDialogInfo | undefined>(undefined);

    const orgsData = useSWR([
        "/orgs", pb],
        async ([url, pb]) => {
            if (!pb) {
                return;
            }
            return await pb.collection("orgs").getFullList({
                sort: "name",
            })
                .then((res) => {
                    return res.map((item) => {
                        return OrgService.transformRecord(item);
                    });
                });
        }
    );

    return <div style={{ width: "100%", height: "100%", alignContent: "center" }}>
        <Container maxWidth="sm" sx={{
            borderStyle: "solid",
            borderWidth: "thin",
            borderColor: "#ffffff45",
            padding: "1rem",
        }}>
            <div style={{ width: "100%", textAlign: "center" }}>
                <Typography variant="h4" component="h1" sx={{
                    fontWeight: "bold"
                }}>
                    Select an organization
                </Typography>
                <Typography variant="caption" gutterBottom sx={{
                    color: "#ffffffaa"
                }}>
                    Logged in as {user?.email}
                </Typography>
            </div>
            <Typography variant="h5" component="h1" gutterBottom sx={{
                fontWeight: "bold"
            }}>
                Organizations
            </Typography>
            <Typography variant="caption" gutterBottom sx={{
                color: "#ffffffaa"
            }}>
                Organizations are the top-level entities in Simple Deploy. They contain projects and users.
            </Typography>
            <List sx={{
                minWidth: 320,
                '& .MuiListItem-root': {
                    cursor: "pointer",
                    "&:hover": {
                        backgroundColor: "#163451ad"
                    },
                    "&.selected": {
                        backgroundColor: "#163451ad"
                    },
                    '& .MuiListItemText-root': {
                        textAlign: "center"
                    }
                },
            }}>
                {orgsData && orgsData.data ? orgsData.data.map((k, i) => {
                    return <OrgListItem key={i} org={k} />;
                }) : undefined}
                {orgsData && orgsData.data && orgsData.data.length === 0 ? <ListItem>
                    <Typography variant="body2" color="text.secondary" sx={{ whiteSpace: "pre-wrap" }}>
                        You are not part of any organization. Create one!
                    </Typography>
                </ListItem> : undefined}
                {orgsData && orgsData.isLoading ? <ListItem sx={{
                    justifyContent: "center",
                }}>
                    <CircularProgress />
                </ListItem> : undefined}
            </List>
            <Button onClick={() => {
                setDialogInfo({
                    displayName: "Add Organization",
                    dataJSONSchema: dataJSONSchema
                });
            }}>+ CREATE ORG</Button>
        </Container>
        <JSONFormDialog info={dialogInfo} onSave={(data) => {
            console.log("Save", data);
            data.members = [user?.id];
            data.limits = {
                maxRegistryStorageMB: 1000,
            };
            return pb.collection("orgs").create(data)
                .then((res) => {
                    orgsData.mutate();
                });
        }} onClose={() => {
            setDialogInfo(undefined);
        }} />
    </div>;
}

import { Box, Dialog, DialogTitle, DialogContent, Tabs, Tab } from "@mui/material";
import { Deployment } from "../domain/Deployment";
import React from "react";


export interface LogDialogInfo {
    deployment: Deployment;
    logs: {
        [key: string]: {
            name: string;
            lines: string[];
        }
    };
}

export interface LogDialogProps {
    onClose: () => void;
    info?: LogDialogInfo;
    hideDeploymentEvents?: boolean;
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

export default function LogDialog({ onClose, info, hideDeploymentEvents }: LogDialogProps) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return <Dialog open={info !== undefined} onClose={() => {
        onClose();
    }} PaperProps={{
        sx: {
            minWidth: '70%',
            borderRadius: '0',
            backgroundColor: "#190782",
        }
    }} fullWidth>
        <DialogTitle>{'Logs'}</DialogTitle>
        <DialogContent>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                {info?.logs ? Object.keys(info.logs).map((key, i) => {
                    return <Tab key={i} label={key} {...a11yProps(i)} />
                }) : undefined}
                {!hideDeploymentEvents ? <Tab label="Deployment events" {...a11yProps(info?.logs ? Object.keys(info.logs).length : 0)} />:undefined}
            </Tabs>
            {info?.logs ? Object.keys(info.logs).map((key, i) => {
                return <CustomTabPanel key={i} value={value} index={i}>
                    {info.logs[key].lines.map((line, o) => {
                        return <pre key={o}>{line}</pre>
                    })}
                </CustomTabPanel>
            }) : undefined}
            <CustomTabPanel value={value} index={info?.logs ? Object.keys(info.logs).length : 0}>
                {info?.deployment && info?.deployment.logs ? info?.deployment.logs.map((log, i) => {
                    return <pre key={i}>{log.text}</pre>
                }) : undefined}
            </CustomTabPanel>
        </DialogContent>
    </Dialog>
}
import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';
import { fieldDescriptionControl, fieldDescriptionControlTester } from '../components/custom-renderers/DescriptionControl';

export interface FormData {
    data: any,
    errors: any,
    loading: boolean
}

export interface JSONFormDialogInfo {
    displayName: string;
    initialData?: any;
    dataJSONSchema: any;
    uiSchema?: any;
}

export interface JSONFormDialogProps {
    onClose: () => void;
    info?: JSONFormDialogInfo;
    onSave: (data: any) => Promise<void>;
}


export default function JSONFormDialog({ onClose, info, onSave }: JSONFormDialogProps) {

    const [formState, setFormState] = React.useState<FormData>({
        data: info?.initialData || {},
        errors: [],
        loading: false
    });

    React.useEffect(() => {
        if (info?.initialData) {
            setFormState({
                data: info.initialData,
                errors: [],
                loading: false
            });
        }
    }, [info?.initialData]);

    return <Dialog fullWidth open={info !== undefined} onClose={onClose} PaperProps={{
        sx: {
            width: "50%",
            minHeight: "60vh",
            borderRadius: '1.5rem',
            backgroundColor: "#190782",
        }
    }}>
        <DialogTitle>{info?.displayName}</DialogTitle>
        <DialogContent sx={{
            paddingTop: "1rem",
        }}>
            <br />
            <JsonForms
                schema={info?.dataJSONSchema}
                uischema={info?.uiSchema}
                renderers={[
                    ...materialRenderers,
                    // register custom renderers
                    {
                        tester: fieldDescriptionControlTester,
                        renderer: fieldDescriptionControl,
                    },
                ]}
                data={formState.data}
                cells={materialCells}
                onChange={({ data, errors }) => {
                    setFormState({
                        data: data,
                        errors: errors,
                        loading: false
                    });
                }}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={() => { onClose() }}>Cancel</Button>
            <Button disabled={formState
                && (
                    (formState.errors
                        && formState.errors.length > 0)
                    || formState.loading === true
                )} onClick={() => {
                    let data = formState.data;
                    setFormState({
                        data: data,
                        errors: [],
                        loading: true
                    });
                    onSave(data).then(() => {
                        setFormState({
                            data: {},
                            errors: [],
                            loading: false
                        });
                        onClose();
                    });
                }}>Save</Button>
        </DialogActions>
    </Dialog>;
}
import { RecordModel } from "pocketbase";
import { Deployment } from "../domain/Deployment";
import DeploymentTargetService from "./DeploymentTargetService";

const DeploymentService = {
    getDeployModel: (deployment: any) => {
        var modelJSON = deployment["model_json"];
        try {
            if (typeof modelJSON === "string") {
                modelJSON = JSON.parse(modelJSON);                
            }
        } catch (e) {
            console.error("Error parsing model_json: ", deployment["model_json"]);
        }
        return modelJSON;
    },
    transformRecord: (record: RecordModel) => {
        var modelJSON = record["model_json"];
        try {
            if (typeof modelJSON === "string") {
                modelJSON = JSON.parse(modelJSON);                
            }
        } catch (e) {
            console.error("Error parsing model_json: ", record["model_json"]);
        }
        let res: Deployment = {
            id: record.id,
            created: new Date(record.created),
            updated: new Date(record.updated),
            deployment_target: record["deployment_target"],
            deploymentTarget: record.expand && record.expand["deployment_target"] 
            ? DeploymentTargetService.transformRecord(record.expand["deployment_target"])
            : undefined,
            message: record["message"],
            status: record["status"],
            model_json: modelJSON,
            logs: record["logs"]
        }
        return res;
    },
}

export default DeploymentService;